import {
    match_search,
    searchCompetitionDocuments,
    search_score,
    user_score_rank,
    competition_entry,
    upload_num,
    is_enter_match,
    search_score_everyday,
    integral_rank,
} from "../../api/SingleItem.js";
import {
    search_question,
    delete_question,
    ask_question,
    answer_question,
} from "../../api/qa.js";
import axios from "axios";
import { publicbaseURLs } from "@/utils/request";
import { getToken } from "@/utils/auth";
import editor from "../ManagementCenter/editor.vue";
import { Notification } from "element-ui";
import * as echarts from "echarts";
export default {
    name: "SingleItem",
    components: {
        editor,
    },
    data() {
        return {
            loading: false,
            EnrollState: -1, //报名入口按钮
            matchstate: 0, //比赛状态
            ThisLogin: getToken(),
            UrlApi: null, //提交文件路劲
            SingleitemData: {}, //单个比赛数据
            SingleitemDataFile: [], //单个比赛数据文件
            UserUploadNumber: null, //当前比赛用户已提交次数
            today_submittimes: "", //用户当天提交次数
            realname: null, //当前比赛用户名称
            PersonalScoreAll: [], //当前比赛用户所有提交历史
            rankingListAll: [], //当前比赛排名
            EntranceChangeState: 0,
            ScoreParams: {
                //分数分页字段
                pageNum: 1,
                pageSize: 10,
            },
            ScoreTotal: 0, //分数分页总数

            rankingeParams: {
                //排名分页字段
                keywords: "",
                pageNum: 1,
                pageSize: 10,
                challenge_id: null,
                filtrate: "score",
            },
            rankingTotal: 0, //排名分页总数

            fileList: [], //文件
            QATitle: "", //提问搜索
            Params: {
                //提问分页
                pageNum: 1,
                pageSize: 8,
            },
            Total: 0, //分页总数
            dataList: [], //提问数据
            level_info: -1,
            show: -1,
            question_id: null,
            replyState: false,
            State: false,
            question_content: null,
            answer_content: null,
            datascore: false,
            activeName: "first",
            ParamsRank: {
                keywords: "",
                challenge_id: "",
                pageNum: 1,
                pageSize: 10,
            },
            RankList: [],
            RankTotal: 0,
        };
    },
    created() {
        this.UrlApi =
            publicbaseURLs() + "/score_calculation/" + this.$route.query.id;
        // 获取比赛状态
        this.matchstate = this.$route.query.matchstate;
        // 默认展示比赛简介
        this.OclickEntrance(0);
        // 判断是否为个人信息页面跳转传参
        if (this.$route.query.state) {
            this.OclickEntrance(this.$route.query.state);
        }
        // 判断报名入口显示状态
        if (getToken()) {
            this.GetEnrollState();
        } else {
            this.EnrollState = -1;
        }
    },
    mounted() {},
    methods: {
        // 是否报名
        GetEnrollState() {
            is_enter_match({ challenge_id: this.$route.query.id })
                .then((res) => {
                    if (res.code == 200) {
                        this.EnrollState = res.registration_status;
                    }
                })
                .catch((err) => {
                    this.EnrollState = err.registration_status;
                });
        },
		// 废除积分功能
		// GetintegralRank(id,type){
		// 	if (type == "搜索"){
		// 		this.ParamsRank.pageNum = 1;
		// 		this.ParamsRank.pageSize = 10;
		// 	} 
		// 	this.ParamsRank.challenge_id = id;
		// 	integral_rank(this.ParamsRank).then((res) => {
        //         if (res.code == 200) {
        //             this.RankList = res.data.list;
        //             this.RankTotal = res.data.total;
        //         }
        //     });
		// },
        // 获取提问问题回复数据
        getdataQA(Id) {
            search_question(Id, this.Params).then((res) => {
                this.dataList = res.data.list;
                this.Total = res.data.total;
            });
        },
        handleClick(tab, event) {
			console.log(tab, event);
		},
        // 发送提问
        sendOutQA() {
            ask_question({
                question_content: this.question_content,
                challenge_id: this.$route.query.id,
            }).then((res) => {
                if (res.code == 200) {
                    this.State = false;
                    this.getdataQA(this.$route.query.id);
                    this.$message({
                        type: "success",
                        message: "发起提问成功!",
                    });
                }
            });
        },
        // 发布回答
        sendOutreply() {
            answer_question({
                question_id: this.question_id,
                answer_content: this.answer_content,
            }).then((res) => {
                if (res.code == 200) {
                    this.replyState = false;
                    this.getdataQA(this.$route.query.id);
                    this.$message({
                        type: "success",
                        message: "发布回答成功!",
                    });
                }
            });
        },
        // 发起回复
        reply(item) {
            if (getToken()) {
                this.question_id = item.question_id;
                this.replyState = true;
            } else {
                this.$message({
                    message: "请先登录用户",
                    type: "warning",
                });
                this.$router.push("/login");
            }
        },
        // 发起提问
        launchQA() {
            if (getToken()) {
                this.State = true;
                this.question_content = null;
            } else {
                this.$message({
                    message: "请先登录用户",
                    type: "warning",
                });
                this.$router.push("/login");
            }
        },
        // 点击查看回复
        ChangeState(item, index) {
            if (item.length > 0) {
                if (this.show == index) {
                    this.show = -1;
                    return;
                }
                this.show = index;
            } else {
                this.$message({
                    message: "暂无回复信息",
                    type: "warning",
                });
            }
        },
        // 删除评论
        DeleteComment(item) {
            this.$confirm("此操作将永久删除该评论, 是否继续?", "提示", {
                confirmButtonText: "删除",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    delete_question({ question_id: item.question_id }).then(
                        (res) => {
                            if (res.code == 200) {
                                this.getdataQA(this.$route.query.id);
                                this.$message({
                                    type: "success",
                                    message: "删除成功!",
                                });
                            }
                        }
                    );
                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: "已取消删除",
                    });
                });
        },
        // 删除回复
        DeleteReply(item, index) {
            var CopyParams = JSON.parse(JSON.stringify(this.Params));
            this.$confirm("此操作将永久删除该回复, 是否继续?", "提示", {
                confirmButtonText: "删除",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    delete_question({ answer_id: item.answer_id }).then(
                        (res) => {
                            if (res.code == 200) {
                                this.Params = CopyParams;
                                this.getdataQA(this.$route.query.id);
                                this.show = index;
                                this.$message({
                                    type: "success",
                                    message: "删除成功!",
                                });
                            }
                        }
                    );
                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: "已取消删除",
                    });
                });
        },
        SingleitemDow(Url) {
            window.location.href = Url;
            return;
        },
        RegistrationParameters() {
            if (getToken()) {
                competition_entry({
                    challenge_id: this.SingleitemData.challenge_id,
                })
                    .then((res) => {
                         if (res.code == 200) {
                            this.$message({
                                type: "success",
                                message: "报名成功",
                            });
                            // this.EnrollState = 1
                            this.GetEnrollState();
                        }
                    })
                    .catch(() => {
                        // this.$message({
                        //   type: "error",
                        //   message: "报名失败",
                        // });
                    });
            } else {
                this.$message({
                    message: "请先登录用户",
                    type: "warning",
                });
                this.$router.push("/login");
            }
        },
        OclickEntrance(item) {
            if (item == 0) {
                this.getdata(this.$route.query.id);
                this.EntranceChangeState = item;
            } else if (item == 1) {
                this.getdataFile(this.$route.query.id);
                this.EntranceChangeState = item;
            } else if (item == 2) {
                if (getToken()) {
                    this.EntranceChangeState = item;
                    this.getdatascore(this.$route.query.id);
                    this.$nextTick(() => {
                        search_score_everyday({
                            challenge_id: this.$route.query.id,
                        }).then((res) => {
                            this.getdatascoreLineChar(
                                res.date_list,
                                res.socre_list
                            );
                        });
                    });
                } else {
                    this.$message({
                        message: "请先登录用户",
                        type: "warning",
                    });
                    this.$router.push("/login");
                }
            } else if (item == 3) {
                if (getToken()) {
                    // if(this.matchstate == -1){
                    //   this.$message({
                    //     message: "比赛已暂停，无法提交",
                    //     type: "warning",
                    //   });
                    //   return
                    // }
                    this.GetDataUserUploadNumner(this.$route.query.id);
                    this.EntranceChangeState = item;
                } else {
                    this.$message({
                        message: "请先登录用户",
                        type: "warning",
                    });
                    this.$router.push("/login");
                }
            } else if (item == 4) {
                this.getdataranking(this.$route.query.id);
                this.EntranceChangeState = item;
            } else if (item == 5) {
                this.level_info = sessionStorage.getItem("level_info");
                this.getdataQA(this.$route.query.id);
                this.EntranceChangeState = item;
            } 
			// else if (item == 6) {
			// 	this.GetintegralRank(this.$route.query.id);
            //     this.EntranceChangeState = item;
            // } 
			else {
                return;
            }
        },
        // 获取比赛简介
        getdata(id) {
            match_search({
                challenge_id: id,
            }).then((res) => {
                this.SingleitemData = res;
            });
        },
        // 获取比赛文件
        getdataFile(id) {
            searchCompetitionDocuments({
                challenge_id: id,
            }).then((res) => {
                this.SingleitemDataFile = res.data;
            });
        },
        // 获取个人分数
        getdatascore(id) {
            var ID = id || this.$route.query.id;
            this.ScoreParams.challenge_id = ID;
            search_score(this.ScoreParams)
                .then((res) => {
                    this.PersonalScoreAll = res.data.list;
                    this.ScoreTotal = res.data.total || 0;
                })
                .catch((err) => {
                    Notification.error({
                        title: err.msg + "，暂无数据",
                    });
                });
        },
        getdatascoreLineChar(date_list, socre_list) {
            var myChart = echarts.init(document.getElementById("sLineChars"));
            var fontColor = "#30eee9";
            var option = {
                backgroundColor: "#11183c",
                grid: {
                    left: "5%",
                    right: "5%",
                    top: "5%",
                    bottom: "12%",
                    containLabel: true,
                },
                tooltip: {
                    show: true,
                    trigger: "item",
                },
                xAxis: [
                    {
                        type: "category",
                        boundaryGap: false,
                        axisLabel: {
                            color: fontColor,
                        },
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: "#397cbc",
                            },
                        },
                        axisTick: {
                            show: false,
                        },
                        splitLine: {
                            show: false,
                            lineStyle: {
                                color: "#195384",
                            },
                        },
                        data: date_list,
                    },
                ],
                yAxis: [
                    {
                        type: "value",
                        name: "",
                        min: 0,
                        max: 1,
                        axisLabel: {
                            formatter: "{value}",
                            textStyle: {
                                color: "#2ad1d2",
                            },
                        },
                        axisLine: {
                            lineStyle: {
                                color: "#27b4c2",
                            },
                        },
                        axisTick: {
                            show: false,
                        },
                        splitLine: {
                            show: true,
                            lineStyle: {
                                color: "#11366e",
                            },
                        },
                    },
                ],
                series: [
                    {
                        name: "每日分数",
                        type: "line",
                        stack: "AUC",
                        symbol: "circle",
                        symbolSize: 8,
                        itemStyle: {
                            normal: {
                                color: "#0092f6",
                                lineStyle: {
                                    color: "#0092f6",
                                    width: 1,
                                },
                            },
                        },
                        markPoint: {
                            itemStyle: {
                                normal: {
                                    color: "red",
                                },
                            },
                        },
                        data: socre_list,
                    },
                ],
                dataZoom: [
                    {
                        show: true,
                        height: 12,
                        xAxisIndex: [0],
                        bottom: 20,
                        start: 0,
                        end: 70,
                        handleIcon:
                            "path://M306.1,413c0,2.2-1.8,4-4,4h-59.8c-2.2,0-4-1.8-4-4V200.8c0-2.2,1.8-4,4-4h59.8c2.2,0,4,1.8,4,4V413z",
                        handleSize: "100%",
                        handleStyle: {
                            color: "#d3dee5",
                        },
                        textStyle: {
                            color: "#fff",
                        },
                        borderColor: "#90979c",
                    },
                    // {
                    //     type: "inside",
                    //     show: true,
                    //     height: 10,
                    //     start: 1,
                    //     end: 35,
                    // },
                ],
            };
            myChart.setOption(option);
        },
        // 获取当前比赛用户提交次数
        GetDataUserUploadNumner(id) {
            var ID = id || this.$route.query.id;
            upload_num({ challenge_id: ID })
                .then((res) => {
                    if (res.code == 200) {
                        this.UserUploadNumber = res.submittimes;
                        this.today_submittimes = res.today_submittimes;
                        this.realname = res.realname;
                        return true;
                    }
                })
                .catch(() => {
                    return false;
                });
        },
        // 获取个人排名
        getdataranking(id, type) {
            if (type == "搜索") {
                this.rankingeParams.pageNum = 1;
                this.rankingeParams.pageSize = 10;
            }
            this.loading = true;
            var ID = id || this.$route.query.id;
            this.rankingeParams.challenge_id = ID;
            integral_rank(this.rankingeParams).then((res) => {
                if (res.code == 200) {
                    this.rankingListAll = res.data.list;
                    this.rankingTotal = res.data.total || 0;
                    this.loading = false;
                } else {
                    this.loading = false;
                }
            });
        },
		ChangeRank(id,type){
			this.loading = true;
            var ID = id || this.$route.query.id;
            this.rankingeParams.challenge_id = ID;
			this.rankingeParams.filtrate = type;
            integral_rank(this.rankingeParams).then((res) => {
                if (res.code == 200) {
                    this.rankingListAll = res.data.list;
                    this.rankingTotal = res.data.total || 0;
                    this.loading = false;
                } else {
                    this.loading = false;
                }
            });
		},
        // 上传之前判断类型
        handleBeforeUpload(file) {
            var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
            // .jpg,.jpeg,.png,.pdf,.PDF
            const extension1 = testmsg === "xlsx";
            // const isLt2M = file.size / 1024 / 1024 < 10
            if (!extension1) {
                this.$message({
                    message: "上传文件只能是.xlsx格式!",
                    type: "warning",
                });
                return false;
            } else {
                return true;
            }
        },
        handleChange(res) {
            this.fileList = res.raw;
        },
        handleSuccess() {
            this.$store.commit("app/CHANGE_LOADING", true);
            var data = new FormData();
            data.append("file", this.fileList);
            data.append("token", getToken());
            axios({
                url: this.UrlApi,
                method: "post",
                data: data,
                headers: {
                    Authorization: getToken(),
                    "Content-Type": "multipart/form-data",
                },
            })
                .then((res) => {
                    if (res.data.code == 200) {
                        this.$message({
                            type: "success",
                            message: "上传成功",
                        });
                        this.GetDataUserUploadNumner(this.$route.query.id);
                        this.$store.commit("app/CHANGE_LOADING", false);
                        this.fileList = [];
                        this.EntranceChangeState = 2;
                        this.getdatascore(this.$route.query.id);
                    } else {
                        this.$message({
                            type: "error",
                            message: res.data.msg,
                        });
                        this.$store.commit("app/CHANGE_LOADING", false);
                        this.fileList = [];
                    }
                })
                .catch(() => {
                    this.$message({
                        type: "error",
                        message: "上传失败",
                    });
                    this.$store.commit("app/CHANGE_LOADING", true);
                    this.fileList = [];
                });
        },
        handleError() {},
    },
};
